.item {
  display: flex;
  align-items: center;
  position: relative;
  background: #fff;
  z-index: 9999;
  >div:first-child {
    width: 0 !important; }
  &:hover {
    .delete {
      opacity: 1;
      transform: translate(-100%, -50%);
      pointer-events: all;
      transition-delay: 1200ms; } } }

.delete {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-90%, -50%);
  pointer-events: none;
  opacity: 0;
  transition: all 250ms ease-out; }

.title {
  flex: 1; }
