.checklist {
  list-style: none;
  margin-left: 0;
  padding: 0;
  margin-bottom: 0;
  li + li {
    margin-top: .3em; } }

.add {
  margin-left: -12px;
  margin-top: -7px; }
